import React from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import bigLogo from '../static/images/ab-logo.png'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { arrDate } from '../utils/createTime'
import bannerCommunity from '../static/images/community-banner.png'

export default Media

function Media() {
  let pageModel = new PageModelTitle('AB Wallet', 'abwallet-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const currentLanguage = intl.locale
  if (currentLanguage == 'zh') {
    return (
      <div>
        <div className={'community-banner'}>
          <h2 className={'container banner'}>
          <span>{intl.formatMessage({ id: 'Media Reports' })}</span>
          <img className={'img'} src={bannerCommunity} alt="banner img" />
          </h2>
        </div>
        <div className={'container community-data'}>
          <ul className={'data-list'}>
            <li>
              <a href='https://www.prnewswire.com/apac/zh/news-releases/ab-dao--alpha-technology-ai-302406866.html' target='_blank'>
                <h3>2025年3月20日</h3>
                <p>AB DAO 携手 Alpha Technology，开启 AI+区块链新篇章</p>
              </a>
            </li>
            <li>
              <a href='https://www.jinse.cn/blockchain/3710035.html' target='_blank'>
                <h3>2025年3月7日</h3>
                <p>AB DAO发布新版NewPay 召唤全球2万老用户认领6500万AB</p>
              </a>
            </li>
            <li>
              <a href='https://www.jinse.cn/blockchain/3709937.html' target='_blank'>
                <h3>2025年3月6日</h3>
                <p>AB代币已上线BingX  用户充值&交易瓜分620万AB奖励</p>
              </a>
            </li>
            <li>
              <a href='https://news.qq.com/rain/a/20250303A07Q0300' target='_blank'>
                <h3>2025年3月3日</h3>
                <p>AB代币已上线Bitget，超350万美元空投助力全球生态扩展</p>
              </a>
            </li>
            <li>
              <a href='https://www.itiger.com/hans/news/2514267573' target='_blank'>
                <h3>2025年2月28日</h3>
                <p>AB即将上线Bitget创新区和Web3区</p>
              </a>
            </li>
            <li>
              <a href='https://www.jinse.cn/blockchain/3708576.html' target='_blank'>
                <h3>2025年2月12日</h3>
                <p>AB 生态基金启动 Web3 影院探索电影业去中心化变革</p>
              </a>
            </li>
            <li>
              <a href='https://news.qq.com/rain/a/20250211A08F2M00' target='_blank'>
                <h3>2025年2月11日</h3>
                <p>HTX、MEXC、4E 交易所将于2月18日执行 $NEW-$AB 交易代码更名</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/zh/news/newton-is-renamed-to-ab-decentralized-governance-is-upgraded-and' target='_blank'>
                <h3>2025年2月10日</h3>
                <p>Newton 更名为 AB，增强去中心化治理社区迎重大利好</p>
              </a>
            </li>
            <li>
              <a href='https://www.theblockbeats.info/flash/281032' target='_blank'>
                <h3>2025年2月9日</h3>
                <p>AB DAO 生态加速发展，代币即将登陆更多交易所</p>
              </a>
            </li>
            <li>
              <a href='https://www.jinse.cn/blockchain/3708350.html' target='_blank'>
                <h3>2025年2月8日</h3>
                <p>Newton正式更名为 AB，开启全新篇章</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className={'community-banner'}>
            <h2 className={'container banner'}>
            <span>{intl.formatMessage({ id: 'Media Reports' })}</span>
            <img className={'img'} src={bannerCommunity} alt="banner img" />
            </h2>
        </div>
        <div className={'container community-data'}>
          <ul className={'data-list'}>
            <li>
              <a href='https://www.coinlive.com/news/ab-dao-global-recall-continues-65-million-new-09e-still' target='_blank'>
                <h3>{arrDate('2025-03-25')}</h3>
                <p>AB DAO Global Recall Continues: 65 Million NEW-09E Still Awaiting Conversion to $AB</p>
              </a>
            </li>
            <li>
              <a href='https://www.prnewswire.com/apac/news-releases/ab-to-be-listed-on-gateio-on-march-29-kicking-off-trade-to-win-airdrop-event-302409346.html' target='_blank'>
                <h3>{arrDate('2025-03-24')}</h3>
                <p>AB to Be Listed on Gate.io on March 29, Kicking Off "Trade to Win Airdrop" Event</p>
              </a>
            </li>
            <li>
              <a href='https://www.prnewswire.com/in/news-releases/ab-dao-partners-with-alpha-technology-to-pioneer-the-aiblockchain-era-302405710.html' target='_blank'>
                <h3>{arrDate('2025-03-19')}</h3>
                <p>AB DAO Partners with Alpha Technology to Pioneer the AI+Blockchain Era</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/news/ab-dao-releases-newpay-update-calls-on-21-989-users-to' target='_blank'>
                <h3>{arrDate('2025-03-07')}</h3>
                <p>AB DAO Releases NewPay Update, Calls on 21,989 Users to Claim 65 Million AB</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/news/ab-token-is-now-live-on-bingx-deposit' target='_blank'>
                <h3>{arrDate('2025-03-06')}</h3>
                <p>AB Token is Now Live on BingX – Deposit & Trade to Share 6.2 Million AB Rewards</p>
              </a>
            </li>
            <li>
              <a href='https://blocktelegraph.io/ab-token-now-live-on-bitget-with-a-3-5-million-airdrop-to-boost-global-ecosystem-expansion' target='_blank'>
                <h3>{arrDate('2025-03-03')}</h3>
                <p>AB Token Now Live on Bitget, with a $3.5 Million Airdrop to Boost Global Ecosystem Expansion</p>
              </a>
            </li>
            <li>
              <a href='https://www.investing.com/news/cryptocurrency-news/ab-to-list-on-bitget-in-the-innovation-and-web3-zone-marking-a-key-milestone-in-global-ecosystem-expansion-3899506' target='_blank'>
                <h3>{arrDate('2025-02-28')}</h3>
                <p>AB to List on Bitget in the Innovation and Web3 Zone, Marking a Key Milestone in Global Ecosystem Expansion</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/news-flash/739503' target='_blank'>
                <h3>{arrDate('2025-02-12')}</h3>
                <p>AB Ecosystem Fund Launches Web3 Cinema Project to Drive Decentralization in the Film Industry</p>
              </a>
            </li>
            <li>
              <a href='https://www.theblockbeats.info/en/flash/281329' target='_blank'>
                <h3>{arrDate('2025-02-11')}</h3>
                <p>HTX, MEXC, and 4E Exchanges to Implement $NEW - $AB Ticker Change on February 18</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/news/newton-is-migrating-to-ab' target='_blank'>
                <h3>{arrDate('2025-02-10')}</h3>
                <p>Newton Renamed to AB: Decentralized Governance Upgrade Brings Major Benefits to AB DAO Community</p>
              </a>
            </li>
            <li>
              <a href='https://www.cointime.ai/flash-news/ab-dao-97067' target='_blank'>
                <h3>{arrDate('2025-02-09')}</h3>
                <p>AB DAO: AB Tokens Will Be Listed on More Exchanges Soon</p>
              </a>
            </li>
            <li>
              <a href='https://www.coinlive.com/news/newton-is-migrating-to-ab' target='_blank'>
                <h3>{arrDate('2025-02-08')}</h3>
                <p>Newton is Migrating to AB</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}